import { RecaptchaVerifier } from "firebase/auth";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { auth } from "@libs/utils/firebase/auth";

export const useRecaptchaVerifier = (): RecaptchaVerifier | null => {
  if (
    process.env.DEPLOY_ENV === "test" ||
    Cookies.get("magicpod-access") ||
    Cookies.get("cypress-access")
  ) {
    // テスト環境とMagicPodからのアクセスはreCAPTCHAを無効化する
    auth.settings.appVerificationDisabledForTesting = true;
  }

  const [recaptchaVerifier, setRecaptchaVerifier] =
    useState<RecaptchaVerifier | null>(null);

  const id = "recaptchaContainer";

  useEffect(() => {
    // Append node if not exists.
    if (!document.querySelector("#" + id)) {
      document.body.insertAdjacentHTML("afterend", `<div id="${id}" />`);
    }

    setRecaptchaVerifier(
      new RecaptchaVerifier(auth, id, {
        size: "invisible",
        callback: (): void => {
          console.log("recaptureVerification done successfully");
        },
      })
    );

    return () => {
      // Ensure node are cleaned on unmount.
      document.querySelector("#" + id)?.remove();
    };
  }, []);

  return recaptchaVerifier;
};
