import classNames from "classnames";
import { Text } from "@components/Text";
import styles from "./index.module.css";
import type { ReactNode, FC } from "react";

type Props = {
  label: ReactNode;
  labelClass?: string;
  subLabel?: ReactNode;
  valueClass?: string;
  value: ReactNode;
  valueError?: ReactNode;
  secondValue?: ReactNode;
  secondValueError?: ReactNode;
  toolTip?: ReactNode;
};

export const FormError: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className={styles.formError}>
      <Text size={12} color="red">
        {children}
      </Text>
    </div>
  );
};

export const FormRow: FC<Props> = ({
  label,
  labelClass,
  toolTip,
  subLabel,
  value,
  secondValue,
  valueError,
  secondValueError,
  valueClass,
}) => {
  const formattedLabel =
    typeof label === "string" ? (
      <Text tag={"div"} size={14} bold>
        {label}
        {toolTip}
      </Text>
    ) : (
      label
    );

  const formattedSubLabel =
    typeof subLabel === "string" ? (
      <Text size={12} color="gray">
        {subLabel}
      </Text>
    ) : (
      subLabel
    );

  const formattedValue =
    typeof value === "string" ? <Text size={15}>{value}</Text> : value;

  const formattedSecondValue =
    typeof secondValue === "string" ? (
      <Text size={15}>{secondValue}</Text>
    ) : (
      secondValue
    );

  const formattedValueError =
    typeof valueError === "string" ? (
      <FormError>{valueError}</FormError>
    ) : (
      valueError
    );

  const formattedSecondValueError =
    typeof secondValueError === "string" ? (
      <FormError>{secondValueError}</FormError>
    ) : (
      secondValueError
    );

  return (
    <dl className={styles.form}>
      <dt className={classNames(styles.formTitle, labelClass)}>
        {formattedLabel}
        {formattedSubLabel && formattedSubLabel}
      </dt>
      <dd className={valueClass}>
        {formattedValue}
        {formattedValueError && formattedValueError}
      </dd>

      {formattedSecondValue && (
        <dd>
          {formattedSecondValue}
          {formattedSecondValueError && formattedSecondValueError}
        </dd>
      )}
    </dl>
  );
};
