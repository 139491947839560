import { signOut } from "@libs/utils/firebase/auth";

type ResultType = () => Promise<void>;

export const useLogout = (): ResultType => {
  return async (): Promise<void> => {
    await signOut();
  };
};

export default useLogout;
