import Alert from "@components/Alert";
import styles from "./index.module.css";
import type { FC } from "react";

const AuthRequiredAlert: FC = () => {
  return (
    <div className={styles.alert}>
      <Alert>
        ネット予約や店舗のお気に入り機能をご利用する場合は、新規登録（無料）またはログインが必要です。
      </Alert>
    </div>
  );
};

export default AuthRequiredAlert;
