import type { RegisterOptions } from "react-hook-form";

type PhoneNumberFormValidationRuleKeys = "phoneNumber";

type PhoneNumberFormValidationRule = {
  [key in PhoneNumberFormValidationRuleKeys]: RegisterOptions;
};

const INVALID_NUMBER = "電話番号の形式が間違っています";

export const phoneNumberFormValidation: PhoneNumberFormValidationRule = {
  phoneNumber: {
    pattern: {
      value: /^0[7-9]0-\d{4}-\d{4}$/,
      message: INVALID_NUMBER,
    },
  },
};
