import { FirebaseError } from "@firebase/util";
import Link from "next/link";
import { useRouter } from "next/router";
import { useCallback, useEffect } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import AuthRequiredAlert from "@components/AuthRequiredAlert";
import { Button } from "@components/Button";
import { CenterAlignedTitle } from "@components/CenterAlignedTitle";
import { CommonHead } from "@components/CommonHead";
import CommonLayout from "@components/CommonLayout";
import LoginRequiredLayout from "@components/LoginRequiredLayout";
import type { PhoneNumberInputForm } from "@components/PhoneNumberInput";
import PhoneNumberInput from "@components/PhoneNumberInput";
import { Text } from "@components/Text";
import Toaster from "@components/Toaster";
import Auth from "@hooks/useAuth";
import { useRecaptchaVerifier } from "@hooks/useAuth/recaptchaVerifier";
import AuthResult from "@hooks/useAuthResult";
import useLogout from "@hooks/useLogout";
import Message, { PredefinedMessages } from "@hooks/useMessage";
import PhoneNumberBackup from "@hooks/usePhoneNumberBackup";
import { paths } from "@libs/paths";
import {
  signInWithPhoneNumber,
  translateToastErrorMessage,
} from "@libs/utils/firebase/auth";
import * as phoneNumberUtils from "@libs/utils/phone_number";
import styles from "./index.module.css";
import type { NextPage } from "next";

const Login: NextPage = () => {
  const router = useRouter();
  const {
    isCurrentUserReady,
    isFanstaUserReady,
    isFanstaUserRegisteredAndValidated,
  } = Auth.useContainer();
  const { setConfirmationResult, confirmationResult } =
    AuthResult.useContainer();
  const logout = useLogout();
  const { addMessage } = Message.useContainer();

  useEffect(() => {
    if (!isCurrentUserReady || !isFanstaUserReady) {
      return;
    }
    if (isFanstaUserRegisteredAndValidated) {
      router.push(paths.home);
      return;
    }
    // 意図的な不要な操作に対応するため明示的にログアウトして、トークン情報等はクリアしておく。
    if (!confirmationResult) {
      logout();
    }
  }, [
    isCurrentUserReady,
    isFanstaUserReady,
    isFanstaUserRegisteredAndValidated,
    confirmationResult,
  ]);
  const { phoneNumberBackup, setPhoneNumberBackup } =
    PhoneNumberBackup.useContainer();

  const methods = useForm<PhoneNumberInputForm>({
    defaultValues: {
      phoneNumber: phoneNumberBackup.phoneNumber,
    },
    mode: "onBlur",
  });
  const {
    handleSubmit,
    control,
    formState: { isValid, isSubmitting },
  } = methods;

  const phoneNumber = useWatch({
    name: "phoneNumber",
    control,
  });

  const recaptchaVerifier = useRecaptchaVerifier();

  useEffect(() => {
    if (phoneNumber === undefined) {
      return;
    }
    setPhoneNumberBackup({
      ...phoneNumberBackup,
      phoneNumber: phoneNumber,
    });
  }, [phoneNumber]);

  const onSubmit = useCallback(
    async (input: PhoneNumberInputForm): Promise<void> => {
      if (!recaptchaVerifier) {
        return;
      }

      try {
        const result = await signInWithPhoneNumber(
          phoneNumberUtils.toFirebaseFormat(input.phoneNumber),
          recaptchaVerifier
        );

        setConfirmationResult(result);
        router.push(paths.loginAuth);
      } catch (err: unknown) {
        console.log("error", err);

        if (err instanceof FirebaseError) {
          addMessage(translateToastErrorMessage(err.code));
        }
      }
    },
    [recaptchaVerifier, setConfirmationResult]
  );

  const isButtonDisabled = !(
    phoneNumber?.length === 13 &&
    isValid &&
    !isSubmitting
  );

  return (
    <CommonLayout noHeaderButton noModal>
      <CommonHead title="ログイン" ogType={"website"} />
      {isCurrentUserReady &&
        isFanstaUserReady &&
        !isFanstaUserRegisteredAndValidated && (
          <>
            <Toaster
              messageFilter={[
                PredefinedMessages.commonError,
                PredefinedMessages.firebaseTooManyCodeRequests,
              ]}
            />

            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <LoginRequiredLayout>
                  <CenterAlignedTitle label="ログイン" />
                  <AuthRequiredAlert />
                  <PhoneNumberInput />
                  <Button
                    className={styles.btn}
                    styleType="filled"
                    colorType="primary"
                    disabled={isButtonDisabled}
                  >
                    ログイン
                  </Button>

                  <div className={styles.link}>
                    <Text size={14}>
                      アカウントをお持ちでない方
                      <Link href={paths.signup}>新規登録</Link>
                    </Text>
                  </div>
                </LoginRequiredLayout>
              </form>
            </FormProvider>
          </>
        )}
    </CommonLayout>
  );
};

export default Login;
