import styles from "./index.module.css";
import type { FC, ReactNode } from "react";

type Props = {
  children?: ReactNode;
};
const Alert: FC<Props> = ({ children }) => {
  return <div className={styles.alert}>{children}</div>;
};

export default Alert;
