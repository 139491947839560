import { phoneNumberFormValidation } from "@validations/phoneNumberForm";
import { validationOfRequired } from "@validations/required";
import classNames from "classnames";
import _ from "lodash";
import { useRef, useState } from "react";
import * as React from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { FormRow } from "@components/FormRow";
import { zen2han } from "@libs/utils/str";
import styles from "./index.module.css";
import type { FC } from "react";

export type PhoneNumberInputForm = {
  phoneNumber: string;
};

const PhoneNumberInput: FC<{ required?: boolean }> = ({ required }) => {
  const name = "phoneNumber";
  const maxLength = 13;

  const [inputLength, setInputLength] = useState<number>(0);
  const {
    clearErrors,
    setValue,
    control,
    trigger,

    formState: { errors },
  } = useFormContext<PhoneNumberInputForm>();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const value = useWatch({ name });

  const assistInputPhoneNumber = (input: string): string => {
    const lengthOnChangeBefore = inputLength;
    const lengthOnChangeAfter = input.length;

    let formatted = input.replace(/[^0-9]/g, "");

    if (lengthOnChangeBefore < lengthOnChangeAfter) {
      if (formatted.length >= 3) {
        formatted = formatted.slice(0, 3) + "-" + formatted.slice(3);
        if (formatted.length >= 8) {
          formatted = formatted.slice(0, 8) + "-" + formatted.slice(8);
        }
      }
    } else {
      if (lengthOnChangeAfter > 3) {
        if (input.indexOf("-") < 0 || input.indexOf("-") > 3) {
          formatted = formatted.slice(0, 2) + formatted.slice(3);
        }

        formatted = formatted.slice(0, 3) + "-" + formatted.slice(3);

        if (lengthOnChangeAfter > 8) {
          if (input.indexOf("-", 4) < 0) {
            formatted = formatted.slice(0, 7) + formatted.slice(8);
          }

          formatted = formatted.slice(0, 8) + "-" + formatted.slice(8);
        } else {
          if (input.indexOf("-", 4) < 0) {
            formatted = formatted.slice(0, 7);
          }
        }
      } else {
        if (input.indexOf("-") < 0) {
          formatted = formatted.slice(0, 2);
        }
      }
    }

    return formatted;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const inputElement = inputRef.current;
    if (!inputElement) {
      return;
    }

    const inputType: string = _.get(e, "nativeEvent.inputType", "");
    // For IME input.
    if (inputElement && inputType === "insertCompositionText") {
      // IME(全角入力)を確定させるために遅延を付ける。
      requestAnimationFrame(() => {
        inputElement.blur();
        inputElement.focus();
      });
    }

    const inputValue = zen2han(e.target.value);
    const inputWithHyphen = assistInputPhoneNumber(inputValue);
    const isValidPhoneNumber = /^[\d|-]+$/.test(inputValue);

    if (inputWithHyphen.length > maxLength) {
      requestAnimationFrame(() => {
        // Rollback input value.
        inputElement.value = value ?? "";
      });
      return;
    }

    setValue(name, inputWithHyphen);
    requestAnimationFrame(() => {
      // Override input value by normalized one.
      inputElement.value = inputWithHyphen;
    });
    setInputLength(inputWithHyphen.length);
    clearErrors(name);

    if (!isValidPhoneNumber || inputWithHyphen.length === maxLength) {
      trigger();
    }
  };

  return (
    <FormRow
      label="電話番号"
      value={
        <Controller
          control={control}
          name={name}
          rules={{
            ...phoneNumberFormValidation.phoneNumber,
            required: required && validationOfRequired,
          }}
          render={({ field: { name, ref }, fieldState: { invalid } }) => {
            return (
              <input
                className={classNames(styles.input, invalid && styles.error)}
                type="tel"
                name={name}
                placeholder="例：080-1234-5678"
                defaultValue={value}
                onChange={(e) => {
                  handleChange(e);
                }}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                ref={(e) => {
                  ref(e);
                  if (inputRef) {
                    inputRef.current = e;
                  }
                }}
              />
            );
          }}
        />
      }
      valueError={errors[name]?.message}
    />
  );
};

export default PhoneNumberInput;
